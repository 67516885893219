<template>
    <div class="pics-container">
        <breadcrumb></breadcrumb>
        <div class="container-title">
            学·非遗
        </div>
        <div class="pics-category-box">
            <category :categoryIndex="categoryIndex" @change="changeCategory" :categorys="categorys"></category>
        </div>
        <list :list="list" @toDetail="toDetail">
        </list>
        <!-- 分页组件 -->
        <pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList" />
    </div>
</template>

<script>
import {
    getPicsPage
} from "@/api/pics/pics";
export default {
    name: 'picsList',
    data() {
        return {
            categoryIndex: 1,//分类下标
            //分类
            categorys: [{
                name: '视频',
                path: '/xuefeiyi/videoList'
            }, {
                name: '非遗作品',
                path: '/xuefeiyi/picsList'
            }, {
                name: '音频',
                path: '/xuefeiyi/audioList'
            }],
            pageNo: 1,//页码
            pageSize: 9,//每页条数
            total: 0,//总数量
            list: [],//列表
        }
    },
    methods: {
        /**
         * 详情
         */
        toDetail(item) {
            this.$router.push({path:'./picsDetail/'+item.id})
            // console.log(item)
        },
        /**
         * 获取列表
         */
        getList() {
            getPicsPage({
                pageNo: this.pageNo,
                pageSize: this.pageSize,
            }).then(res => {
                if (res.code == 0) {
                    this.list = res.data.list
                    this.total = res.data.total
                }
            })
        },
        /**
         * 选择分类
         */
        changeCategory(index) {
            this.categoryIndex = index
            this.$router.push(this.categorys[this.categoryIndex].path)
        }
    },
    created() {
        //获取列表
        this.getList()
    }
}
</script>

<style lang="scss" scoped>
.pics-container {
    width: 1200px;
    margin: 0 auto;

    .container-title {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        color: #181B1C;
        font-family: XiaoWei-Regular;
    }

    .play-icon {
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: .3s;
    }

    .list-item:hover {
        .play-icon {
            opacity: 1;
        }
    }

}
</style>